import React from "react"
import PropTypes from "prop-types"
import * as styles from "./__styles__/cards.module.scss"

import Card from "./../molecules/card"

// Images
import image01 from "./../../images/svgs/cards/modal.svg"
import image02 from "./../../images/svgs/cards/chart.svg"
import image03 from "./../../images/svgs/cards/pie.svg"
import image04 from "./../../images/svgs/cards/stats.svg"

export default function Cards({ cards }) {
  const images = [image01, image02, image03, image04]

  return (
    <div className={styles.container}>
      {cards.map((card, index) => (
        <div className={styles.card} key={index}>
          <Card
            svg={images[index % images.length]}
            title={card.title}
            copy={card.copy}
            link={card.link}
          />
        </div>
      ))}
    </div>
  )
}

Cards.propTypes = {
  /**
   * A list of cards (array of objects)
   */
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      copy: PropTypes.string.isRequired,
      link: PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string.isRequired,
      }).isRequired,
    })
  ),
}
